<template>
  <div class="fatherBox">
    <div class="headerBox">
      <p>数据列表
      </p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <el-button type="primary" @click="(dialogVisibles = true)">导出数据</el-button>
    </div>

    <div class="query">
      <div class="queryLeft">
        <div style="float: left ;width: fit-content;margin-right: 10px;">
          学生查询:
          <el-input v-model="studentName" placeholder="请输入学生姓名"></el-input>
        </div>&nbsp;&nbsp;
        <div>
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="ClassStudentsList(1)">查询</el-button>
        </div>
      </div>

      <div class="queryRight" style="    display: flex;flex-direction: row-reverse;margin-right: 20px;">
        <div class="dataBox" @click="classEchart()">
          <div class="num">{{classlhv}}</div>
          <div class="text">班级近视率</div>
        </div>
      </div>
    </div>
    <el-table :data="ClassStudentsInfos" v-loading="loading">
      <el-table-column label="学生姓名" prop="StuName" width="auto" align="center"></el-table-column>
      <el-table-column label="长编号" prop="StuNumber" width="auto" align="center"></el-table-column>
      <el-table-column label="短编号" prop="short_num" width="auto" align="center"></el-table-column>
      <el-table-column label="裸眼视力(左)" prop="LEye" width="auto" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.LEye?scope.row.LEye:'暂无'}}</span>
          <span v-if="scope.row.lbgnum" style="margin-left:5px;">
            <span v-if="scope.row.lbgc==1" style="color:green;"> {{scope.row.lbgnum}}</span>
            <span v-if="scope.row.lbgc==2" style="color:red;"> {{scope.row.lbgnum}}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="裸眼视力(右)" prop="REye" width="150" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.REye?scope.row.REye:'暂无'}}</span>
          <span v-if="scope.row.rbgnum" style="margin-left:5px;">
            <span v-if="scope.row.lbgc==1" style="color:green;"> {{scope.row.rbgnum}}</span>
            <span v-if="scope.row.lbgc==2" style="color:red;"> {{scope.row.rbgnum}}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="班级排名" prop="crank" width="150" align="center"></el-table-column>
      <el-table-column label="年级排名" prop="grank" width="150" align="center"></el-table-column>
      <el-table-column label="全区排名" prop="arank" width="150" align="center"></el-table-column>
      <el-table-column label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="doDetail(scope.$index, scope.row)">查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="CountNum > 0">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
      </el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible" title='班级历史近视率'>
      <div id="main" style="width: 98%; height: 350px; background: white"></div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibles"
      title='导出数据'
      width="500px"
    >
      <div>
        <el-form label-width="80px" class="demo-ruleForm" label-position="left">
          <el-form-item label="日期" >
            <el-date-picker
             style="width: 350px;"
              v-model="form.day"
              value-format="yyyy-MM-dd"
              type="date"
              :picker-options="pickerOptions"
              placeholder="请选择日期"
              >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibles = false">取 消</el-button>
          <el-button type="primary" @click="doExport">确 定</el-button>
        </span>
      </div>
    </el-dialog>


  </div>
</template>
<script>
  import Vue from 'vue'
  import {
    classStudentsDataList,
    classGoodPic,
    excelDaochueye
  } from '@/api/SchoolApi.js'
  import {
    mapGetters
  } from 'vuex'
  export default {
    computed: {
      ...mapGetters(['Status'])
    },
    data() {
      return {
        ClassStudentsInfos: [],
        loading: false, // 加载框
        pagesize: 12, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        gradeId: '',
        classId: '',
        classlhv: '',
        Series: [], // 折线图Y轴数据
        Dates: [], // 折线图X轴数据
        dialogVisible: false,
        studentName: '',
        dialogVisibles: false,
        form: {
          day: '',
          bstatus: 1
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
      }
    },
    mounted() {
      this.gradeId = this.$route.params.gradeId
      this.classId = this.$route.params.classId
      this.ClassStudentsList(1) // 获取学生列表
    },
    methods: {
      reset() { // 重置按钮
        this.ClassStudentsInfos = []
        this.studentName = ''
        this.currpage = 1
        this.ClassStudentsList(this.currpage)
      },
      ClassStudentsList(currpage) {
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize, //	每页条数
          classId: this.classId,
          gradeId: this.gradeId,
          search: this.studentName
        }
        classStudentsDataList(data).then(res => {
          this.ClassStudentsInfos = res.data.List
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.classlhv = res.data.jsl
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) {
        this.ClassStudentsList(val)
        this.currpage = val
      },
      drawChart() {
        const that = this
        const myChart = this.$echarts.init(document.getElementById('main'))
        const options = {
          // title:{text:'Echarts数据'},
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['近视率']
          },
          xAxis: {
            data: that.Dates
          },
          yAxis: {},
          series: [{
            name: '近视率',
            type: 'line',
            data: that.Series
          }]
        }
        myChart.setOption(options)
      },
      classEchart() { // 全校近视率-折线图
        this.dialogVisible = true
        const param = {
          ClassID: this.classId
        }
        classGoodPic(param).then(res => {
          // console.log(res)
          this.Series = res.data.jnum
          this.Dates = res.data.date
          this.drawChart()
        }).catch(err => {
          console.log(err)
        })
      },
      // 查看详情
      doDetail(index, row) {
        this.$router.push({ // 页面跳转
          name: 'classStudentDetailTea', // 往这个页面跳转 classStudentDetail
          params: { // 跳转传递的参数
            StuID: row.sid,
            StuName: row.StuName
          }
        })
      },
      doExport () { // 导出
        if (!this.form.day) {
          this.$message.error('请选择日期！')
          return false
        }

        excelDaochueye(this.form).then(res => {
          if (res.status.code == 1) {
            window.open(Vue.prototype.$url + res.data)
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  #body {
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
  .dataBox{
    padding:5px 20px;
    font-size:18px;
    cursor:pointer;
    .num{
      color:#409EFF
    }
  }
  .dataBox1{
    padding:5px 20px;
    font-size:18px;
    .num{
      color:#409EFF
    }
  }
</style>
